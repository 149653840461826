





















import { Component, Mixins } from 'vue-property-decorator'
import { initPayPal, initSession } from '@/api/payment'
import { AbstractCartPaymentForm } from '@/mixins/abstract-cart-payment-form'
import VLoadSpinner from '~/components/VLoadSpinner.vue'
import { CartStep, CartStore } from '~/store/cart'
import CartHeading from '@/components/cart/CartHeading.vue'
import { log } from 'util'

@Component({ components: { VLoadSpinner, CartHeading } })
export default class CartPaymentFormPaypalDirect extends Mixins(AbstractCartPaymentForm) {
    CartStep = CartStep
    paymentPlatform = 'PayPal to Producer'

    @CartStore.Mutation('SET_CART_STEP') SET_CART_STEP: (cartStep: CartStep) => void

    async payWithPaypal() {
        try {
            this.SET_LOADING_STATE(true)
            const orderId = await initSession(this.initSessionParams)
            const returnUrl = `${location}/finish-paypal/${orderId}`
            const cancelUrl = `${location}`
            const paypalObject = await initPayPal(orderId, returnUrl, cancelUrl)
            // opens paypal checkout
            window.location.replace(paypalObject.link)
        } catch (error: any) {
            this.paymentError = error.response.data
            console.error(error)
        } finally {
            this.SET_LOADING_STATE(false)
        }
    }
}
