import { Component, Vue } from 'vue-property-decorator'
import { CartStep, CartStore } from '~/store/cart'
import { analyticsOrderCompleted } from '~/services/analytics'
import { ModalStore } from '~/store/modal'
import { AuthStore } from '~/store/auth'
import { isProductPage } from '@elitepage/utils/utils'

@Component
export class AbstractCartPaymentForm extends Vue {
    paymentPlatform = ''
    customer = {
        name: this.$store.state.user.authenticatedUser ? this.$store.state.user.authenticatedUser.first_name : '',
        email: this.$store.state.user.authenticatedUser ? this.$store.state.user.authenticatedUser.email : ''
    }
    // errors on payments
    paymentError: object = null

    @AuthStore.Getter('isAuthenticated') isAuthenticated: boolean
    @CartStore.Getter('cartItemsWithDeals') cartItemsWithDeals: ICartItem[]
    @CartStore.State('coupons') coupons: ICoupon[]
    @CartStore.Getter('cartTotalAmount') cartTotalAmount: number
    @ModalStore.State('isLoading') isLoading: boolean

    @CartStore.Mutation('SET_CART_STEP') SET_CART_STEP: (cartStep: CartStep) => void
    @CartStore.Mutation('RESET_CART_ITEMS') RESET_CART_ITEMS: () => void
    @ModalStore.Mutation('SET_LOADING_STATE') SET_LOADING_STATE: (payload: boolean) => void

    get paymentErrorShort() {
        return this.paymentError instanceof String ? this.paymentError.substring(0, 300) : this.paymentError
    }

    get initSessionParams(): InitSessionParams {
        return {
            customer_email: this.customer.email,
            cart: this.cartItemsWithDeals,
            coupons: this.coupons,
            payment_platform: this.paymentPlatform
        }
    }

    onCompletedPayment(orderData: IOrderDataSimple) {
        // TODO do better
        if (isProductPage())
            Vue.router.replace({ name: 'ProductCheckoutSuccessful', params: { email: orderData.email, orderId: orderData.id } })
        else
            Vue.router.replace({ name: 'OrderReview', params: { email: orderData.email, orderId: orderData.id } })
        // clear the cart
        this.RESET_CART_ITEMS()
        // Google Analytics & Facebook Pixel ecommerce event
        analyticsOrderCompleted(orderData)
    }

}
