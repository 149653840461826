import { get, post } from '~/services/http-requests'
import { CreditCardInfo } from 'braintree-web'

const _BS_PAYMENT = 'payment'

async function fetchSavedPaymentInfo(): Promise<CreditCardInfo> {
    const data = await get({ fullUrl: `${_BS_PAYMENT}/get_saved_payment_info/braintree/`, isFreeRequest: true })
    return data.saved_credit_card
}

async function initSession(initSessionParams: InitSessionParams): Promise<number> {
    const sessionData = await post({ fullUrl: `${_BS_PAYMENT}/init_session/`, dispatchParams: initSessionParams, isFreeRequest: true })
    return sessionData.order_id
}

async function initBraintree(orderId: number, paymentParams: IPaymentParams): Promise<IOrderDataSimple> {
    return post({ fullUrl: `${_BS_PAYMENT}/init_braintree/${orderId}/`, dispatchParams: paymentParams, isFreeRequest: true })
}

function initPayPal(orderId: number, returnUrl: string, cancelUrl: string): Promise<any> {
    return post({ fullUrl: `${_BS_PAYMENT}/init_paypal/${orderId}/`, dispatchParams: {return_url: returnUrl, cancel_url: cancelUrl}, isFreeRequest: true })
}

function paypalCaptureOrder(orderId: number, approvedPayPalOrderId: string): Promise<any> {
    return get({ fullUrl: `${_BS_PAYMENT}/paypal_capture_order/${orderId}/${approvedPayPalOrderId}/`, isFreeRequest: true })
}

export { fetchSavedPaymentInfo, initSession, initBraintree, initPayPal, paypalCaptureOrder }
