






import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class CartHeading extends Vue {
    @Prop({ type: String }) title: string
    @Prop({ type: String }) subtitle: string
}
